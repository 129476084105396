import { useEffect, useState } from "react";

const useContact = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        company: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        const errors = {};
        if (!formData.fullName.trim()) {
            errors.fullName = 'Full Name is required';
        }
        if (!formData.company.trim()){
            errors.company = 'Company is requird';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }
        setErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setIsFormValid(Object.keys(errors).length === 0);
    }, [errors]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (isFormValid) {
            handleSubmit();
        }
    };


    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    return {
        handleChange,
        isValidEmail,
        handleSubmit,
        handleFormSubmit,
        setIsFormValid,
        isFormValid,
        errors,
        formData,
    }
}

export default useContact;
