import './Projects.css';

const Projects = () => {
 
  return (
    <div>

      <h1 className='textg'>PROJECTS</h1>
        <div className='flex'>

          <div className='papersP'>
            <div className='flex1'>
                <h2 className='text1PROJ'>Customs Business Process Reengineering</h2>
              <p className='text1pp'>
                On the project of customs business process reengineering, MindWise consultants worked in close partnership with the team of Masshtab Consultancy in order to analyze and reengineer business processes and procedures at the customs border control points, including guidelines on border crossing procedures for taxpayers
              </p>
            </div>
            <div id='Index'>
              <a className='read' href="#popup">Read more...</a>
            </div>

            <div id='popup'>
                <div>
                  <a className='pop' href="#index">X</a>
                  <h2 className='text'>Customs Business Process Reengineering</h2>
                  <p>
                    On the project of customs business process reengineering, MindWise consultants worked in close partnership with the team of Masshtab Consultancy in order to analyze and reengineer business processes and procedures at the customs border control points, including guidelines on border crossing procedures for taxpayers. The in-depth analyst and the recommendation of the team allowed for better experience of tax-payers at border control points, significant cost savings for the government, as well as helped start a number of projects that were aimed at implementing IT systems to automate various government functions at the border.
                  </p>
                </div>
            </div>
          </div>

          <div className='papersP'>
            <div className='flex2'>
              <h2 className='text2' >
                Assistance and Consultation on Computer Systems and Artificial Intelligence 
              </h2>
              <p className='text2pp'>
                In the framework of this project, our company completed two phases of work. In the first phase, titled 'Assistance and Consultation on Architectural Design of Computer Systems and Multi-Service Communication Software,' our primary goal was to enhance the architecture of software that ensures efficient and secure operation on multitasking systems. 
              </p>
            </div>
            <div id='Index1'>
              <a className='read1' href="#popup1">Read more...</a>
            </div>

            <div id='popup1'>
                <div>
                  <a className='pop1' href="#index1">X</a>
                  <h2 className='text'>
                    Assistance and Consultation on Computer Systems & Multi-services Communication Software Design and development of Machine Learning (ML) and Artificial Intelligence (AI) Based Analytical Models 
                  </h2>
                  <p>
                    In the framework of this project, our company completed two phases of work. In the first phase, titled 'Assistance and Consultation on Architectural Design of Computer Systems and Multi-Service Communication Software,' our primary goal was to enhance the architecture of software that ensures efficient and secure operation on multitasking systems. Our company successfully played the role of a consultant, analyzing user needs and issues, and providing recommendations for creating reliable and scalable architectural solutions. Additionally, we developed APIs and security measures to ensure seamless integration and data protection. We also conducted a review of the source code, identifying and rectifying inefficiencies and vulnerabilities. In the second phase, 'Design and Development of Analytical Models Based on Machine Learning (ML) and Artificial Intelligence (AI),' our company developed innovative ML and AI models. These models were configured to utilize user-generated data to enhance user satisfaction, retention, and predict various events. We designed and implemented a machine learning model for intrusion detection and prevention, as well as a user behavior analysis module. Special attention was given to developing algorithms for phishing and fraud detection, along with classification algorithms for SSL/TLS certificate validation.This project delivered modern and secure software, along with analytical models to optimize user experience and enhance system security. Our company completed the project on time, and our expertise in architectural design and machine learning provided significant advantages in a competitive environment. This project marked a crucial step in strengthening our technological infrastructure and ensuring the reliable protection of data and systems.
                  </p>
                </div>
            </div>
          </div>   

          <div className='papersP'>
            <div className='flex3'>
              <h2 className='text3'>
                Computer Systems & Communication Equipment Software Design
              </h2>
              <p className='text3pp'>
                Our company successfully completed the design of computer systems and communication equipment software, including the design, implementation, operation, and maintenance of computer system software. These tasks involved analyzing user problems and their need for cost-effective solutions, as well as developing the necessary programs to implement such solutions. 
              </p>
            </div>

            <div id='Index2'>
              <a className='read2' href="#popup2">Read more...</a>
            </div>

            <div id='popup2'>
                <div>
                  <a className='pop2' href="#index2">X</a>
                  <h2 className='text'>
                    Computer Systems & Communication Equipment Software Design
                  </h2>
                  <p>
                    Our company successfully completed the design of computer systems and communication equipment software, including the design, implementation, operation, and maintenance of computer system software. These tasks involved analyzing user problems and their need for cost-effective solutions, as well as developing the necessary programs to implement such solutions. Additionally, we were involved in designing application software intended for use on smartphones and mobile devices.Our services encompassed: Providing support to our clients, including resolving end-user issues related to the services we provided.Managing the software design and development process.Conducting developer and customer testing.Handling software installation and integration.Providing support, maintenance, and ensuring service levels.Implementing updates and improvements.Delivering other related services.Escrowing the source code. Also, we successfully designed analytical models based on machine learning (ML) and artificial intelligence (AI). These models were tailored to user-generated data and served various purposes, such as evaluating user satisfaction, retaining users, and predicting different types of events.Our services in this area included:Developing models for comprehensive user data analysis.Designing ML/AI prediction models with binary or multi-class outcomes for user classification and event prediction.Creating natural language processing (NLP) models to analyze unstructured and semi-structured data, facilitating decision-making. This encompassed tasks like user sentiment analysis and user feedback analysis.Maintaining and regularly updating the models to incorporate new features and enhance their performance.
                  </p>
                </div>
            </div>
          </div>

          <div className='papersP'>
            <div className='flex4'>
              <h2 className='text4'>
                Mobile Tax Implementation Concept
              </h2>
              <p className='text4pp'>
                Using established benchmarks and the best practices on the market, the team prepared areport on m-Tax implementation, including global best practices, their scope, institutionalarrangements, technology, data management, and implementation modalities in Armenia. The comprehensive report, its gap analysis and action plan with clear sequencing and costing of each of the actions,allows the government of Armenia to start the implementation of comprehensive m-Tax IT systems.
              </p>
            </div>          
          </div>

          <div className='papersP'>
            <div className='flex5'>
              <h2 className='text5'>
                Tax & Customs Risk Management System
              </h2>
              <p className='text5p'>
                In consortium with our partner company, Daniam LLC, the team of Mindwise developed a new IT system that uses AI and ML technologies to automaticallyevaluate tax and customs risks, identify anomalies, and run predictive scenarios for the State Revenue Committee of Armenia.
              </p>
            </div>
          </div>

        </div>
      </div>
  );
}

export default Projects;

