import png1 from '../Home/picH/header_background 1 2 1.svg'
import png4 from './picH/arrow_circle_down 1.svg';
import useHome from './useHome';
import './Home.css';

const Home = () => {

const {
  scrollToElement,
  abouthRef,
  TiltCard,
  TiltCard1,
} = useHome();


  return (
      
    <div className='home'>
      <div className='pic'>
        <h3><img src={png1}  className='nkar' alt=""  /></h3>
        <p className='text'> INNOVATIVE SOFTWARE SOLUTIONS FOR BUSINESSES, GOVERNMENTS, AND ORGANIZATIONS</p>
        <p id='text'> INNOVATIVE SOFTWARE SOLUTIONS FOR BUSINESSES, <br /> GOVERNMENTS, AND ORGANIZATIONS</p>
        <div className='png4'><img  src={png4} alt=""  onClick={scrollToElement} /></div>
      </div>


      <div className='abouth' ref={abouthRef}>

        <div className='flexal'>
          <div className='abutn1'>
            <TiltCard1 /> 
          </div>

          <div className='abut1'>
            <h1 className='about-us'>
                ABOUT  US
            </h1>
            <p className='text12'>
              MINDWISE INFORMATION TECHNOLOGY LLC brings a decade of IT expertise, igniting the possibilities of tomorrow's technology landscape. We bring a comprehensive suite of services to the table, covering diverse aspects of software development. <br /> From project management and technical support to developing innovative solutions and training professionals, we're one of the catalysts that drive growth in the industry. With an astute understanding of  global markets, including the Middle East and Persian Gulf countries, we
                work both in private and government sectors

            </p>
          </div>

          <div className='mobail'>
            <div className='abut1M'>
                <h1 className='about-us'>
                    ABOUT  US
                </h1>
                <p className='text12'>
                  MINDWISE INFORMATION TECHNOLOGY LLC brings a decade of IT expertise, igniting the possibilities of tomorrow's technology landscape. We bring a comprehensive suite of services to the table, covering diverse aspects of software development. From project management and technical support to developing innovative solutions and training professionals, we're one of the catalysts that drive growth in the industry.With an astute understanding of
                </p>
              </div>
              <div className='abutn1M'>
                <TiltCard1 /> 
              </div>
          </div>

        </div>

        <div className='flexal1'>
          <div className='abut2'>
            <h1 className='our-vision'>
                OUR  VISION
            </h1>
            <p className='text123'>
              At MINDWISE, we aim to redefine the standards of IT consulting. By delivering unparalleled analytical services, fostering strategic partnerships in terms of building technology, and offering comprehensive support, we empower our clients to achieve their most ambitious goals.Our vision extends beyond individual projects. <br /> We envision playing a pivotal role in shaping industries, promoting sustainable practices, and driving positive change. Through our dedication to social responsibility and ethical AI & ML practices, we seek to create a world where technology acts as a force for good, benefiting both businesses and society as a whole.
            </p>
          </div>

          <div className='abutn2'>
            <TiltCard />
          </div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  );
}

export default Home;


