import './Services.css';
import png8 from './nkarrrr/bank-svgrepo-com (1).svg';
import png9 from './nkarrrr/board-chart-svgrepo-com.svg';
import png10 from './nkarrrr/book-svgrepo-com.svg';
import png11 from './nkarrrr/write-svgrepo-com (1).svg';
import png12 from './nkarrrr/dna-svgrepo-com.svg';
import png13 from './nkarrrr/play-svgrepo-com.svg';
import png14 from './nkarrrr/share-1-svgrepo-com.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect} from 'react';


const Services = () =>{

  useEffect(() => {
    Aos.init({duration: 5000})
  })


  
  return (
    <div>
      <h1 className='services'>SERVICES</h1>
      <div className='flex'>

        <div className='card'>
          <img src={png13} className='anime' alt="" />
          <img src={png13} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Social Media/Media Streaming/ Gaming Platforms
            </p>
            <p className='p1 '>
              From building vibrant social communities to revolutionizing the way media is consumed, we breathe life into your wildest digital dreams. Seamlessly blending cutting-edge technology with human interaction, our platforms redefine entertainment and create unforgettable moments that leave hearts pounding and smiles wide.
            </p>
          </div>
        </div>

        <div className='card'>
          <img src={png9} className='anime' alt="" />
          <img src={png9} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Software Development
            </p>
            <p className='p1'>
              With fingers on the pulse of innovation, we craft bespoke solutions that merge complexity and simplicity with finesse. Each line of code we write is a symphony of elegance, empowering you to conquer challenges, automate processes, and scale new heights. Seamlessly tailored to your unique needs, we will build software that unveils a universe of efficiency, productivity, and unrivaled user experiences that will leave your competition in awe.
            </p>
          </div>
        </div>

        <div className='card'>
          <img src={png11} className='anime' alt="" />
          <img src={png11} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Web-Design
            </p>
            <p className='p1'>
              Enter a world where stunning aesthetics blend harmoniously with intuitive functionality. Our artistic specialists use pixels and colors to create captivating digital designs. With a careful attention to detail and a strong focus on user-centered design, we craft projects that go beyond the ordinary. You will enjoy your experience with seamless navigation, striking visuals, and purpose-driven layouts that guide visitors through an immersive journey.
            </p>
          </div>
        </div>

        <div className='card'>
          <img src={png12} className='anime' alt="" />
          <img src={png12} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Consulting in AI/ML
            </p>
            <p className='p1'>
              Our team of techno-sages walks alongside you, illuminating the path to unprecedented insights, efficiency, and innovation. Through strategic consulting and the magic of AI/ML, we unlock hidden potential within your data, transforming it into a catalyst for growth and unrivaled decision-making. We help you unleash the power to predict, automate, and revolutionize industries, embracing boundless possibilities.
            </p>
          </div>
        </div>

        <div className='card'>
          <img src={png10} className='anime' alt="" />
          <img src={png10} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Development of technical documentations and functional descriptions
            </p>
            <p className='p1'>
              We untangle complicated details, unveiling the algorithms and features, fostering smooth collaboration between visionaries, developers, and users. With careful attention to every detail, we provide the guidebook for your digital journey, infusing clarity, coherence, and simplicity into each page.
            </p>
          </div>
        </div>

        <div className='card'> 
          <img src={png8} className='anime' alt="" />
          <img src={png8} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Development of e-Gov Solutions
            </p>
            <p className='p1'>
              We build bridges between people and administrations, redefining the relationship between citizens and their digital counterparts. From crafting systems that streamline vehicle declarations to unifying information databases, we empower governments to serve with unparalleled efficiency, transparency, and citizen centricity. Together, we create a world that minimizes bureaucracy.
            </p>
          </div>
        </div>

        <div className='card'>
          <img src={png14} className='anime' alt="" />
          <img src={png14} alt="" className='animation'  data-aos="rotate-c"  />
          <div className='ser'>
            <p className='p'>
              Consulting in ESG
            </p>
            <p className='p1'>
              Embrace the call of conscience and embark on a purpose-driven journey towards sustainable and responsible business practices. Our team of ethical pioneers guides you through the complex realm of Environmental, Social, and Governance (ESG) consulting.
            </p>
          </div>
        </div>
      </div>
    </div>
    
  );
}



export default Services;
