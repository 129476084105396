import png1 from './picC/call.png';
import png4 from './picC/outgoing_mail.png';
import './contact.css'
import useContact from './useContact';

const Contact = () => {

    const {
        handleChange,
        handleFormSubmit,
        isFormValid,
        errors,
        formData,
    } = useContact();


    

    return(
        <>             
            <div class="containe">
                <div class="text-center">
                    <h1>Reach out now</h1>
                    <div>
                        Schedule a free consultation with us to work out your project roadmap.We will find the best solution for your needs.
                    </div>
                </div>
                <div class="content">
                    <div class="col-1">
                        <div id='nkarmap' class="address-line">
                            <div class="contact-info"> 
                            <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28907.397668477373!2d55.17465791751964!3d25.087480750105172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6c0fdcf8c319%3A0xc1b5948ee66f3482!2z0JDQu9GMINCR0LDRgNGI0LAgLSDQlNGD0LHQsNC5IC0g0J7QsdGK0LXQtNC40L3QtdC90L3Ri9C1INCQ0YDQsNCx0YHQutC40LUg0K3QvNC40YDQsNGC0Ys!5e0!3m2!1sru!2sam!4v1711028671197!5m2!1sru!2sam"  
                                    allowfullscreen="" 
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                >
                            </iframe>
                            <div className='map'>
                                <a 
                                    href="https://www.google.com/maps/search/Al+Barsha,+Baraha+Saeed,+Office+G51/@25.0874844,55.1930267,14z/data=!3m1!4b1?entry=ttu" target='_blank'>
                                        <span>Addres:</span>
                                    Al Barsha, Baraha Saeed, Office G51
                                </a>
                            </div>
                            </div>
                        </div>
                        <div class="address-line">
                            <img alt="location" src={png1} class="icon"/>
                            <div class="contact-info">
                                <div class="contact-info-title">Phone:</div>
                                <div>
                                    <a href="tel:+971 50 4796434" className='phonC'> +971 50 4796434 </a>
                                </div>
                            </div>
                        </div>
                        <div class="address-line">
                            <img alt="location" src={png4} class="icon"/>
                            <div class="contact-info">
                                <div class="contact-info-title" className='emailC'>Email:</div>
                                <div className='emailC'>
                                    <a href="mailto:mp@mindwise.global" className='emailC'> mp@mindwise.global </a> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <form action="https://api.web3forms.com/submit" method="POST" className='contactUS'>
                            <div class="form-container">
                                <h2 className='contacth2'>Send Message</h2>
                                <div class="form-row">
                                    <label className='contactlabel'>Full Name <span>*</span> </label>
                                    <div>
                                        <input
                                            type="text" 
                                            className={`form-field ${errors.fullName && 'error-border'}`}
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange} 
                                         />
                                        {errors.fullName && <span className="error" style={{ color: 'red' }}>{errors.fullName}</span>}
                                    </div>
                                </div>
                                <div class="form-row">
                                    <label className='contactlabel'>Company <span>*</span> </label>
                                    <div>
                                        <input 
                                            type="text" 
                                            className={`form-field ${errors.fullName && 'error-border'}`}
                                            name="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                        />
                                        {errors.company && <span  className="error" style={{ color: 'red', }}>{errors.company}</span>}
                                    </div>
                                </div>
                                <div class="form-row">
                                    <label className='contactlabel'>Email <span>*</span> </label>
                                    <div>
                                        <input 
                                            type="text" 
                                            className={`form-field ${errors.fullName && 'error-border'}`}
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        /> 
                                       {errors.email && <span  className="error" style={{ color: 'red', font:'30px' }}>{errors.email}</span>}
                                    </div>
                                </div>
                                <div class="form-row">
                                    <label className='contactlabel'>Type your message...</label>
                                    <div>
                                        <input 
                                            type="text" 
                                            class="form-field"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <input
                                    onClick={handleFormSubmit} 
                                    type="button" 
                                    className={`send-btn ${isFormValid ? '' : 'disabled'}`}
                                    value="Send"
                                    disabled={!isFormValid}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact