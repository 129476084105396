import './Partners.css';
import png1 from './picP/GrantThornton 1.svg';
import png2 from './picP/HUMANIZE 1.svg';
import png3 from './picP/THEWORLDBANK 1.svg';
import png5 from './picP/interpretable 1.svg';
import png6 from './picP/daniam 1 1.svg'

const Partners = () => {

  return (
    <div>
      <h1 className='textg'>PARTNERS</h1>
        <div className='hay'>

          <div className='vs'>
            <a href="https://www.grantthornton.am/" target='_blank'> <img className='n1'  src={png1} alt="" /> </a>
          </div>
          <div className='vs'>
            <a href="https://www.humanize.security/" target='_blank'> <img className='n2' src={png2} alt="" /> </a>
          </div>
          <div className='vs'>
            <a href="https://www.worldbank.org/en/home" target='_blank'> <img className='n3' src={png3} alt="" /> </a>
          </div>
          <div className='vss'>
            <a href="https://www.daniam.am/" target='_blank'> <img className='n4' src={png6} alt="" /> </a>
          </div>
          <div className='vs'>
            <a href="https://www.interpretable.ai/" target='_blank'> <img className='n5' src={png5} alt="" /> </a>
          </div>

        </div>
    </div>
  );
};

export default Partners;
